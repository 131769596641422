import { Buffer } from 'buffer';
// eslint-disable-next-line import/extensions, @typescript-eslint/ban-ts-comment
import { ICustomerLookup } from '../shared/Interfaces';

function getLoginChallenge() {
  return window.location.search.replace(/.*login_challenge=([a-z0-9]+)/, '$1');
}

export async function getCustomers(loginHint: string): Promise<ICustomerLookup[]> {
  const response = await fetch(
    `/login/customers?login_challenge=${getLoginChallenge()}&login_hint=${encodeURIComponent(
      Buffer.from(loginHint).toString('base64'),
    )}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  );

  if (!response.ok) {
    switch (response.status) {
      case 404:
        throw new Error('Invalid login session. Go back to your application to start the login process over');

      default:
        throw new Error('Oops, something went wrong! Please try again.');
    }
  }

  const { customers } = (await response.json()) as { customers: Array<ICustomerLookup> };
  return customers;
}
