import * as React from 'react';
import Card from '@scuf/common/dist/components/Card/Card';
import './CenteredCard.scss';

import logo from '../logo.svg';

const CenteredCardContent = ({ children, className }: { children: any, className?: string }) => {
  return (
    // eslint-disable-next-line prefer-template
    <Card.Content className={'centered-card-content' + (className ? ' ' + className : '')}>
      <div className="logo">
        <img className={logo} src={logo} height="72px" alt="logo"/>
      </div>
      {children}
    </Card.Content>
  );
};

export default class CenteredCard extends React.Component {
  static Content = CenteredCardContent;

  render() {
    return (
      <div className="centered-card-wrapper">
        <Card className="centered-card" interactive={false}>
          {(this.props as any).children}
        </Card>
      </div>
    );
  }
}