import * as React from 'react';
import CenteredCard from '../CenteredCard/CenteredCard';
import { IRegistrationStateConfig } from '../../shared/Interfaces';

declare const config: IRegistrationStateConfig; 

export default class RegistrationPage extends React.Component {
  render() {
    return (
      <CenteredCard>
        <CenteredCard.Content>
          {config.registration_state === 'success' && (
            <React.Fragment>
              <h3>Thank you for Registering!</h3>
              <p>
                  Your registration process is completed. <br/>
                  Please return to your application to login.
              </p>
            </React.Fragment>
          )}
          {config.registration_state === 'canceled' && (
            <React.Fragment>
              <h3>Registration Canceled</h3>
              <p>
                  You have canceled your registration. Please return to your application.
              </p>
            </React.Fragment>
          )}

        </CenteredCard.Content>
      </CenteredCard>
    );
  }
}