import React, { Component } from 'react';
import '@scuf/common/honeywell/theme.css';
import Login from './Login/Login';
import { ILoginConfig } from '../shared/Interfaces';
import ContinueAs from './ContinueAs/ContinueAs';
import ErrorCard from './ErrorCard/ErrorCard';

declare const config: ILoginConfig;

class App extends Component<Record<string, unknown>, { forgetSession: boolean }> {
  state = {
    forgetSession: false
  };

  render() {
    if (config.error && !config.application) {
      return <ErrorCard error={config.error} description={config.error_description} />;
    }

    if (config.application) {
      if (!this.state.forgetSession && config.session && config.session.user && config.session.user.email) {
        return (
          <ContinueAs
            session={config.session}
            application={config.application}
            onForget={() => this.setState({ forgetSession: true })}
          />
        );
      }

      return <Login application={config.application} error={config.error} />;
    }

    return <ErrorCard error="Unknown error" />;
  }
}

export default App;
