import React from 'react';
import Button from '@scuf/common/dist/components/Button/Button';
import Loader from '@scuf/common/dist/components/Loader/Loader';
import { IApplication, ISession } from '../../shared/Interfaces';
import CenteredCard from '../CenteredCard/CenteredCard';

import './ContinueAs.scss';

import { getCustomers } from '../login';

type IContinueAsProps = { session: ISession, onForget: () => void, application: IApplication };
type IContinueAsState = { loading: boolean };

export default class ContinueAs extends React.Component<IContinueAsProps, IContinueAsState> {
  state = {
    loading: false
  };

  render() {
    const { session, onForget } = this.props;
    const { loading } = this.state;
    return (
      <CenteredCard>
        <Loader loading={loading} text={session.customer ? `Logging you in with your ${session.customer.name} account` : "Logging you in"}>
          <CenteredCard.Content className="continue-as-content">
            <h1>Welcome back, {session.user.given_name ? session.user.given_name : session.user.name}</h1>
            {session.user.email ? <h2>{session.user.email}</h2> : session.user.username ? <h2>{session.user.username}</h2> : null}
            <img src={session.user.avatar} className="user-avatar" alt="user-avatar"/>
            <Button fluid={true} className="continue-as-button" onClick={this.onClick}>Sign In</Button>
            <br />
            <Button type="link" onClick={onForget}>Switch to a different account</Button>
          </CenteredCard.Content>
        </Loader>
      </CenteredCard >
    );
  }

  private onClick = async () => {
    const { session } = this.props;
    this.setState({ loading: true });
    const customers = await getCustomers(session.user.email || session.user.username);
    if (customers.length === 0) {
      // Uhh... idk
      return;
    }

    window.location.href = customers[0].redirectUrl;
  }
}