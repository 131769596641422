import React, { Component } from 'react';
import Button from '@scuf/common/dist/components/Button/Button';
import Input from '@scuf/common/dist/components/Input/Input';
import Loader from '@scuf/common/dist/components/Loader/Loader';
import { IApplication, ICustomerLookup } from '../../shared/Interfaces';
import CenteredCard from '../CenteredCard/CenteredCard';
import { MultiSelect } from '../MultiSelect/MultiSelect';
import './Login.scss';
import { getCustomers } from '../login';

type ILoginProps = { application: IApplication, error?: string };
type ILoginState = {
  loginHint: string,
  cardLoading: boolean,
  buttonLoading: boolean,
  customer: ICustomerLookup | null,
  error: string | null,
  selectError: string | null,
  customers: ICustomerLookup[] | null
};

const INTERACTION_DELAY_TIME = 300;

class Login extends Component<ILoginProps, ILoginState> {  
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      loginHint: '',
      cardLoading: false,
      buttonLoading: false,
      customer: null as ICustomerLookup | null,
      customers: null as ICustomerLookup[] | null,
      error: props.error || null as string | null,
      selectError: null
    };
  }

  render() {
    const { application } = this.props;
    const { loginHint, cardLoading, buttonLoading, customer, error, selectError, customers } = this.state;

    return (
      <CenteredCard>
        <Loader loading={cardLoading} text={customer ? `Logging you in with your ${customer.name} account` : undefined}>
          <CenteredCard.Content className="login-content">
            <form onSubmit={this.onClick}>
              <h1>Sign In</h1>
              <Input
                className="login-input"
                name="username"
                label="Email or Username"
                fluid={true}
                onChange={this.onInputChange}
                value={loginHint}
                error={error !== null ? error : undefined}
                spellCheck={false}
                tabIndex={0}
                autoFocus={true}
              />
              <MultiSelect error={selectError} options={customers} onSelect={this.onSelect} selected={customer ? customer.customerId : null} />
              <Button actionType="submit" className="login-button" onClick={this.onClick} loading={buttonLoading}>Next</Button>
              <br />
              {
                // Hydra will return null if there are no contacts.
                application.contacts && application.contacts.length > 0 ?
                  <Button className="support-button" type="link" onClick={this.onContactSupport}>Contact Support</Button> :
                  <div className="support-button-spacer" />
              }
              <div className="footer">
                &copy;{new Date().getFullYear()} Honeywell Inc. All rights reserved
              {application.tosUrl || application.policyUrl ? <br /> : null}
                {application.tosUrl ? <a href={application.tosUrl}>Terms of Service</a> : null}
                {application.tosUrl && application.policyUrl ? " | " : null}
                {application.policyUrl ? <a href={application.policyUrl}>Privacy Policy</a> : null}
              </div>
            </form>
          </CenteredCard.Content>
        </Loader>
      </CenteredCard >
    );
  }

  private onInputChange = (loginHint: string) => {
    this.setState({ loginHint, error: null, selectError: null, customers: null, customer: null });
  }

  private onClick = async (event: any) => {
    event.preventDefault();
    const loginHint = this.state.loginHint.trim();
    const { customer, customers } = this.state;
    const interactionDelay = new Promise((resolve: any) => setTimeout(resolve, INTERACTION_DELAY_TIME));
    
    if (loginHint === '') {
      this.setState({ error: 'Please enter your email address or username' });
      return;
    }

    // User has selected a customer using MultiSelect
    if (customer) {
      return this.redirect(customer);
    }

    // User has not selected a customer, but we have already populated customers for this loginHint
    if (customers) {
      this.setState({ selectError: 'Please select your employer from the list' });
      return;
    }

    // Get customers array
    this.setState({ error: null, buttonLoading: true });
    try {
      const customersResponse = await getCustomers(loginHint);

      if (customersResponse.length === 1) {
        this.redirect(customersResponse[0]);
      } else if (customersResponse.length === 0) {
        this.noCustomersFound(loginHint);
      } else {
        await interactionDelay;
        this.renderCustomerSelect(customersResponse);
      }
    }
    catch (e: any) {
      console.error(e);
      this.setState({ error: e.message, buttonLoading: false });
    }
  }

  private renderCustomerSelect = (customers: ICustomerLookup[]) => {
    this.setState({ customer: null, customers, cardLoading: false, buttonLoading: false });
  }

  private noCustomersFound = (loginHint: string) => {
    this.setState({ error: loginHint.indexOf('@') === -1 ? 'Invalid username' : 'Invalid email address', buttonLoading: false });
  }

  private redirect = (customer: ICustomerLookup) => {
    this.setState({ customer, cardLoading: true, buttonLoading: false });
    window.location.href = customer.redirectUrl;
  }

  private onContactSupport = () => {
    const { application } = this.props;
    window.location.href = `mailto:${application.contacts.join(',')}`;
  }

  private onSelect = (customerId: string) => {
    const { customers } = this.state;
    const selectedCustomer = customers!.find((oneCustomer) => oneCustomer.customerId === customerId);
    this.setState({ customer: selectedCustomer!, selectError: null });
  }
}

export default Login;
