import * as React from 'react';
import CenteredCard from '../CenteredCard/CenteredCard';
import Button from '@scuf/common/dist/components/Button/Button';
import './ErrorCard.scss';

type IErrorCardProps = { error: string, description?: string };
type IErrorCardState = { showTechnicalDetails: boolean };

export default class ErrorCard extends React.Component<IErrorCardProps, IErrorCardState> {
  state = {
    showTechnicalDetails: false
  };

  render() {
    const { error, description } = this.props;
    const { showTechnicalDetails } = this.state;
    return (
      <CenteredCard>
        <CenteredCard.Content>
          <h3>{error}</h3>
          <p>
            This could be a misconfiguration in the system or a service outage.
            In the meantime, you can try going back to your application by hitting the back button.
            If this problem persists, please contact your systems administrator.
          </p>
          <br />
          <Button type="secondary" onClick={this.onBack}>Go Back</Button>
          {description && <br />}
          {description && <a onClick={this.onTechnicalDetailsClicked}>{showTechnicalDetails ? 'Hide' : 'Show'} technical details for this error</a>}
          {showTechnicalDetails && <pre className="error-description">{description}</pre>}
        </CenteredCard.Content>
      </CenteredCard>
    );
  }

  private onBack = () => {
    window.history.back();
  }

  private onTechnicalDetailsClicked = () => {
    this.setState((prevProps) => ({ showTechnicalDetails: !prevProps.showTechnicalDetails }));
  }
}