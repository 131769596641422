import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import 'es6-promise/auto';

import * as ReactDOMClient from 'react-dom/client';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import App from './App';
import RegistrationPage from './Registration/RegistrationPage';

function RouterMain() {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/registration/*" component={RegistrationPage} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  );
}

const container = document.getElementById('root') as HTMLDivElement;
const root = ReactDOMClient.createRoot(container);
root.render(<RouterMain />);
