import React, { Component } from 'react';
import './MultiSelect.scss';
import { ICustomerLookup } from '../../shared/Interfaces';
import Select from '@scuf/common/dist/components/Select/Select';

const ANIMATION_MS = 300;
const ANIMATION_S = ANIMATION_MS / 1000;
const ANIMATION_STYLE = {
    transition: `max-height ${ANIMATION_S}s ease-in-out`
};

interface IMultiSelectProps {
    options: ICustomerLookup[] | null;
    onSelect: (customerId: string) => void;
    selected: string | null;
    error: string | null;
}

interface IMultiSelectState {
    overflow: boolean;
}

class MultiSelect extends Component<IMultiSelectProps, IMultiSelectState> {

    private timeout: NodeJS.Timeout | null = null;

    constructor(props: IMultiSelectProps) {
        super(props);
        this.state = {
            overflow: false
        };
    }

    componentDidUpdate(lastProps: IMultiSelectProps, _lastState: IMultiSelectState) {
        if (!this.props.options && lastProps.options) {
            this.setState({ overflow: false });
        } else if (this.props.options && !lastProps.options) {
            this.timeout = setTimeout(
                () => this.setState({ overflow: true }),
                ANIMATION_MS
            );
        }
    }

    componentWillUnmount() {
        this.timeout && clearTimeout(this.timeout);
    }

    render() {
        const { options } = this.props;
        const sliderClass = options ? 'slider' : 'slider closed ';
        const overflowClass = this.state.overflow ? ' overflow-visible ' : '';
        return (
            <div
                data-testid="select-wrapper"
                style={ANIMATION_STYLE}
                className={sliderClass + overflowClass + ' multi-select-wrapper'}
            >
                <div className="ui input-label fluid">
                    <span className="input-label-message">
                        Select your employer
                    </span>
                </div>
                {this.genSelector()}
            </div>
        );
    }

    genSelector = () => {
        const { options, onSelect, selected, error } = this.props;

        const selectOptions = options && options.map(customer => {
            return {
                value: customer.customerId,
                text: customer.name
            };
        });

        // TEMPORARY: Until we get images for customers, always render Select component
        // const isShortList = options.length < 4;
        // const isShortList = false;

        // if (isShortList) {
        //     return (
        //         <div className="multi-select">
        //             {
        //                 options && options.map((customer, index: number) => {
        //                     const className = 'item' + (selected === customer.customerId ? ' selected' : '');
        //                     return (
        //                         <div key={index} onClick={() => onSelect(customer.customerId)} className={className}>
        //                             <div>{customer.name}</div>
        //                         </div>
        //                     );
        //                 })
        //             }
        //         </div>
        //     )
        // }
//        else {
        return (
            <Select
                fluid={true}
                onChange={(value) => onSelect(value)}
                placeholder="Type To Search"
                options={selectOptions || []}
                search={true}
                value={selected}
                error={error || ''}
            />
        );
//        }
    }
}

export { MultiSelect };